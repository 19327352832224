import React from 'react';
import SEO from '@/components/SEO';

const NotFoundPage: React.FC = () => (
  <div>
    <SEO title="404 Page not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
  </div>
);

export default NotFoundPage;
